import { request } from './request.js'

export function getBanner() {
    return request({
        url:'/JudicialProtection/queryJpBannerInfoList.json',
    })
}

export function getAbout() {
    return request({
        url:'/Preface/prefaceInfoList.json',
    })
}