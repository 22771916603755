<template>
  <div class="home">
    <header></header>
    <div class="bakImg"></div>
    <div class="main">
      <div class="preface">
        <div class="preface-title">
          <img class="icon" src="~assets/img/home/图层 55 拷贝.png" alt="" />
          <img class="icon-title" src="~assets/img/home/前言.png" alt="" />
          <img class="icon" src="~assets/img/home/图层 55 拷贝.png" alt="" />
        </div>
        <div class="content qy" v-html="text"></div>
        <div v-show="isBtn" class="btn" @click="btnClick">展开全部</div>
      </div>
      <div class="dynamic">
        <div class="dynamic-title">
          <img class="icon" src="~assets/img/home/图层 55 拷贝.png" alt="" />
          <img class="icon-title" src="~assets/img/home/工作动态.png" alt="" />
          <img class="icon" src="~assets/img/home/图层 55 拷贝.png" alt="" />
        </div>
        <van-swipe :show-indicators="false" class="my-swipe" :autoplay="3000">
          <van-swipe-item
            @click="swiperClick(item)"
            :style="setBak(item.pictureUrl)"
            v-for="(item, index) in banner"
            :key="index"
            ><p v-show="item.briefIntroduction">
              {{ item.briefIntroduction }}
            </p></van-swipe-item
          >
        </van-swipe>
      </div>
      <div class="classify">
        <ul class="multi" @click="classifyClick">
          <li
            :data-item="convert(item)"
            :style="setBak(item.image)"
            v-for="(item, index) in multiList"
            :key="index"
            class="item"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getBanner, getAbout } from "@/network/home.js";
export default {
  data() {
    return {
      isBtn: true,
      mySwiper: null,
      text: null,
      banner: [],
      multiList: [
        {
          image: require("@/assets/img/home/微信图片_20201229143717.png"),
          route: "Stkp",
        },
        {
          image: require("@/assets/img/home/微信图片_20201229143659.png"),
          route: "/list?type=1&title=法律法规",
        },
        {
          image: require("@/assets/img/home/微信图片_20210107111639.png"),
          route: "/list?type=0&title=典型案例",
        },
        {
          image: require("@/assets/img/home/微信图片_20210107111709.png"),
          route: "/Zxss",
        },
      ],
    };
  },
  computed: {
    setBak(item) {
      return (item) => {
        return {
          background: `url(${item})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "0",
          backgroundRepeat: "no-repeat",
        };
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const result = await getBanner();
      const textResult = await getAbout();
      if (textResult.code === "1") {
        this.text = textResult.data[0].description;
      }
      if (result.code === "1") {
        this.banner = result.data;
        console.log(this.banner)
      }
    },
    classifyClick(event) {
      const even = event || window.event;
      const tag = even.target || event.srcElement;
      const links = JSON.parse(tag.dataset.item);
      if (links.route) {
        this.$router.push( links.route );
        return;
      }
    },
    convert(item) {
      return JSON.stringify(item);
    },
    swiperClick(item) {
      this.$router.push({
        name: "Content",
        query: { item: JSON.stringify(item) },
      });
    },
    btnClick() {
      document.querySelector(".content").classList.remove("qy");
      this.isBtn = false;
    },
  },
};
</script>
<style scoped>
.my-swipe {
  height: 3.6rem;
  margin-top: 0.29rem;
}
.my-swipe .van-swipe-item {
  position: relative;
  height: 100%;
}
header {
  height: 0.35rem;
  background: url("~assets/img/home/图层 1.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
}
.bakImg {
  height: 1.83rem;
  background: url("~assets/img/home/图层 3.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  top: 0.35rem;
  left: 0;
}
.main {
  padding: 0.64rem 0.41rem;
}
.preface {
  position: relative;
}
.preface-title,
.dynamic-title {
  /* position: absolute;
  left: 50%;
  top: 0.65rem;
  transform: translate(-50%, 0); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.preface-title .icon-title {
  width: 0.74rem;
  height: 0.38rem;
  margin: 0.07rem;
}
.dynamic-title {
  margin-top: 0.48rem;
}
.dynamic-title .icon-title {
  width: 1.46rem;
  height: 0.36rem;
  margin: 0.07rem;
}
.icon {
  width: 0.24rem;
  height: 0.23rem;
}
.preface .content {
  margin-top: 0.28rem;
  font-size: 0.28rem;
}
.qy {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.my-swipe p {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 10px 10px;
  font-size: 0.26rem;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  padding: 0.1rem 0.16rem;
  line-height: 1.3;
  box-sizing: border-box;
}
.classify {
  margin-top: 0.6rem;
}
/* .multi {
  display: flex;
}
.stkp,
.flfg {
  flex: 1;
  height: 2.11rem;
  border: 2px solid #0e4c84;
  border-radius: 0.1rem;
} */
.classify .stkp {
  background: url("~assets/img/home/微信图片_20201229143717.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
  margin-right: 0.1rem;
}
.classify .flfg {
  background: url("~assets/img/home/微信图片_20201229143659.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
}
.classify .single {
  width: 100%;
  height: 1.9rem;
  background: url("~assets/img/home/微信图片_20201229143721.png");
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
  margin-top: 0.1rem;
}
.btn {
  width: 2rem;
  padding: 0.1rem 0;
  font-size: 0.3rem;
  color: #803826;
  border: 2px solid #803826;
  text-align: center;
  font-weight: bold;
  margin: 0.35rem auto 0;
}
.my-swipe {
  width: 100%;
  height: 3rem;
}
.swiper-container {
  width: 100%;
  height: 3rem;
}
.multi .item {
  width: 3.26rem;
  height: 2.11rem;
  background-size: 100% 100%;
  background-position: 0;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 0.14rem;
}
.multi .item:nth-child(2n) {
  margin-right: 0;
}
</style>